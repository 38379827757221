body{
    background-color: #F1F3F5 !important ;
}


.portrait{
    /* position: absolute; */
    max-width: 100%;
    max-height: 250px;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
    top:30px;
    /* right:0; */
}

.portrait-pu{
    /* position: absolute; */
    max-width: 100%;
    max-height: 250px;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
    top:0;
    /* right:0; */
}



.portrait-home{
    position: absolute;
    width:auto;
    left:0;
    right:0;
}

.avatar{
    /* border: #FFFFFF 2px solid; */
    width: 130px !important;
    height: 130px !important;
    left:1rem;
    top:5rem;
   box-shadow: 1px 4px 14px -5px rgba(0,0,0,0.49);
    -webkit-box-shadow: 1px 4px 14px -5px rgba(0,0,0,0.49);
    -moz-box-shadow: 1px 4px 14px -5px rgba(0,0,0,0.49);
}

.avatar img{
    padding: 0 !important;
    border: 6px solid #fff;
    border-radius:50%;
    -moz-border-radius:50%;

}

.bs-group{
  margin-top:35px;
  left:10px;
  
}

.bs{
    
    /* display: flex;
    position: relative;
    align-items: center;
    justify-content: right;
    right:15px;
    top:5px;
    flex-wrap: wrap; */
}

.bs-avatar{
    width: 60px !important;
    height: 60px !important;
    left:25px;
    top:125px;
    /* left:1rem;
    top:5rem; */
}

.bs-avatar img{
    border-radius: 10px;
    width: 45px !important;
    height: 45px !important;
    
    
}

.bs-text{
    margin-top: 132px;
    margin-left: 24px;
   z-index: 99;
  height:80px;
}

.bs-text  p{
    padding: 0;
    margin: 0;
    font-size: 9pt;
    line-height: 14px;
}

.bs-text h1{
    padding: 0;
    margin: 0;
    font-size: 9pt;
    font-weight: 500;
}

.bsp{
    
    display: flex;
    position: relative;
    align-items: center;
    justify-content: right;
    right: 25px;
    top:10px;
    flex-wrap: wrap;
}





.bs-group-edit{
  margin-top:120px;
  left:10px;
  
}

.bsp-group{
  margin-top:115px;
  left:10px;
  
}

.bs-public-group{
  margin-top:115px;
  left:10px;
  
}





.bs-public{
    
    display: flex;
    position: relative;
    align-items: center;
    justify-content: right;
    right: 25px;
    top:10px;
    flex-wrap: wrap;
}



.bs-public--text{
    width: 120px !important;
    padding-left: 7px;
   /* left: 2rem !important; */
}
.pos p,h1,h2,h3,h4,h5,h6{
    z-index: 10;
}



.bsp-avatar{
   width: 60px !important;
    height: 60px !important;
    /* left:1rem;
    top:5rem; */
}

.bs-public-avatar{
   width: 60px !important;
    height: 60px !important;
    /* left:1rem;
    top:5rem; */
}




.bsp-avatar img{
    border-radius: 14px;
}

.bs-public-avatar img{
    border-radius: 14px;
}


.btn{
    /* background-color: #1D1D1B !important; */
    color: #000000 !important;
    border-color: #000000 !important;
    border-radius: 8px !important;
}

.btn-full{
    background-color: #1D1D1B !important;
    color: #FFFFFF !important;
    border-color: #1D1D1B !important;
    border-radius: 8px !important;
}

.bg-shop{
    background-color:#E5E5E5;
    width:100%;
    padding: 20px 10px;
    border-radius: 18px 
}

.prominent{
    background-color:#E9E9EB;
    min-width: 320px;
    width:100%;
    padding: 10px;
    border-radius: 12px;
    
}

/* .prominent .big-icon{
    width:50px;
} */
/* .promi-body{
    min-width:320px;

} */

.promi-body .title{
     font-size: 12pt;
     padding-left: 1rem;
     margin:0 !important                         
}

.promi-body .body{
    font-size: 10pt;
    padding-left: 1rem;
     text-align: "center";  
     margin: 0;      
     overflow-wrap: break-word;                  
}



.bag-icon{
    background-color: #FFFFFF;
    border-radius: 4px;
    color: #5697DD;
    padding: 4px;
    font-size: 40px !important; 

}
.desc fieldset {
    border: none !important;
    outline: none !important;
}


.bs-pro{
    width:15px;
    height:15px;
}

.slim>input[type=file] {
    display:none !important;
}

.slim {
    width:100%;
    background-color:#FFF !important;
}

.slim .slim-area{
    display: none;
}
.slim-btn-group{
    display:none !important
}


.big-icon {
    width:70px;
  filter: drop-shadow(2px 2px 2px rgb(0 0 0 / 0.2));
}